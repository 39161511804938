import { CreateControllerFn, IControllerConfig } from '@wix/yoshi-flow-editor';
import { WixGroupsController } from 'controllers/controller';

import { GroupsWidgetController } from './Controller/GroupsWidgetController';

const createController: CreateControllerFn = async (params) => {
  const legacy = new GroupsWidgetController(params);
  const controller = new WixGroupsController(params);

  return {
    async pageReady($w, wixAPI) {
      await Promise.all([legacy.pageReady($w, wixAPI), controller.pageReady()]);
    },

    updateConfig($w, updatedConfig) {
      legacy.updateConfig($w, updatedConfig as IControllerConfig);
      controller.updateConfig?.($w, updatedConfig as IControllerConfig);
    },

    onBeforeUnLoad() {
      legacy.onBeforeUnLoad();
      controller.onBeforeUnLoad?.();
    },

    exports() {
      return {
        ...legacy.exports(),
      };
    },
  };
};

export default createController;
